import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faMagnifyingGlass,
  faPenToSquare,
  faArrowsRotate,
  faBuildingCircleCheck,
  faEllipsis,
  faEnvelope,
  faUsersViewfinder,
  faCircleCheck,
  faXmark,
  faUserMinus,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSort,
  faSchool,
  faGear,
  faUserPlus,
  faCircleExclamation,
  faCircleQuestion,
  faTriangleExclamation,
  faSquareCheck,
  faPlus,
  faUserCheck,
  faPrint,
  faLandmark,
  faMapLocationDot,
  faBuildingUser,
  faPeopleGroup,
  faUser,
  faUserGroup,
  faBuilding,
  faUsers,
  faAppleWhole,
  faCalendarDays,
  faChalkboardUser,
  faChalkboard,
  faArrowUpRightFromSquare,
  faRotateLeft,
  faUpload,
  faBars,
  faCircleUser,
  faTruck,
  faSignOut,
  faTruckFast,
  faFilePdf,
  faDownload,
  faCartShopping,
  faEdit,
  faRotateRight,
  faUnlock as farUnlock,
  faSpinner,
  faPlusCircle,
  faCircleInfo,
  faCheck,
  faHelmetSafety,
  faHandshake,
  faGears,
  faChair,
  faShuffle,
  faObjectUngroup,
  faFileExport
} from "@fortawesome/free-solid-svg-icons";

import { faUsps } from "@fortawesome/free-brands-svg-icons";
import {
  faClone,
  faCircleXmark,
  faSortUp,
  faSortDown,
  faUserPlus as fadUserPlus,
  faPaperPlane,
  faUserSecret as fadUserSecret
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faSquareDashedCirclePlus,
  faSquareExclamation,
  faDoNotEnter,
  faInfoSquare,
  faSquareChevronUp,
  faFileCertificate,
  faApartment,
  faNotebook,
  faPartyHorn,
  faCreditCardFront,
  faMoneyCheckDollarPen,
  faClockDesk,
  faChevronsLeft,
  faChevronsRight,
  faIndustryWindows,
  faRectangleHistory,
  faOctagonExclamation
} from "@fortawesome/pro-solid-svg-icons";
import {
  faFolderOpen,
  faCircleCheck as farCircleCheck,
  faCircleQuestion as farCircleQuestion
} from "@fortawesome/free-regular-svg-icons";
import { faTypewriter, faFileArrowUp } from "@fortawesome/pro-thin-svg-icons";
import {
  faSquareChevronDown,
  faTag,
  faCircleXmark as farCircleXmark,
  faPenToSquare as farPenToSquare,
  faUnlock,
  faArrowRight,
  faFileCertificate as farFileCertificate,
  faPrint as farPrint,
  faTrashXmark,
  faPlus as farPlus,
  faMinus,
  faArrowLeft,
  faSquareXmark,
  faLocationCheck,
  faLocationDot,
  faCircleInfo as farCircleInfo,
  faUsers as farUsers,
  faUserSecret,
  faListCheck,
  faBrowsers,
  faUserGroup as fasUserGroup,
  faBagShopping
} from "@fortawesome/pro-regular-svg-icons";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import type { App } from "vue";

export default {
  install: (app: App) => {
    library.add(
      faMagnifyingGlass,
      faPenToSquare,
      farPenToSquare as IconDefinition,
      faArrowsRotate,
      faBuildingCircleCheck,
      faEllipsis,
      faEnvelope,
      faUsersViewfinder,
      faCircleCheck,
      faXmark,
      faUserMinus,
      faCaretDown,
      faCaretRight,
      faCaretUp,
      faChevronLeft,
      faChevronRight,
      faChevronDown,
      faChevronUp,
      faSort,
      faSchool,
      faGear,
      faUserPlus,
      faCircleExclamation,
      faCircleQuestion,
      faTriangleExclamation,
      faSquareCheck,
      faPlus,
      faPrint,
      faTag as IconDefinition,
      farCircleXmark as IconDefinition,
      faSquareExclamation as IconDefinition,
      faDoNotEnter as IconDefinition,
      faFileCertificate as IconDefinition,
      faInfoSquare as IconDefinition,
      faSquareDashedCirclePlus as IconDefinition,
      faClone as IconDefinition,
      faCircleXmark as IconDefinition,
      faSquareChevronUp as IconDefinition,
      faSquareChevronDown as IconDefinition,
      faUserCheck,
      faFileCertificate as IconDefinition,
      faLandmark,
      faMapLocationDot,
      faApartment as IconDefinition,
      faBuildingUser,
      faPeopleGroup,
      faUser,
      faUserGroup,
      faBuilding,
      faUsers,
      faAppleWhole,
      faCalendarDays,
      faChalkboardUser,
      faChalkboard,
      faUpload,
      faArrowRight as IconDefinition,
      faCreditCardFront as IconDefinition,
      faMoneyCheckDollarPen as IconDefinition,
      faClockDesk as IconDefinition,
      faNotebook as IconDefinition,
      faNotebook as IconDefinition,
      faSortUp as IconDefinition,
      faBars,
      faCircleUser,
      faSortDown as IconDefinition,
      faArrowUpRightFromSquare,
      faUnlock as IconDefinition,
      faRotateLeft,
      farFileCertificate as IconDefinition,
      farPrint as IconDefinition,
      faPartyHorn as IconDefinition,
      faTruck,
      faSignOut,
      faChevronsLeft as IconDefinition,
      faChevronsRight as IconDefinition,
      faTrashXmark as IconDefinition,
      farPlus as IconDefinition,
      faMinus as IconDefinition,
      faUsps as IconDefinition,
      faTruckFast,
      faFilePdf,
      faDownload,
      faCartShopping,
      faArrowLeft as IconDefinition,
      faEdit,
      faRotateRight,
      faLocationCheck as IconDefinition,
      faSquareXmark as IconDefinition,
      faLocationDot as IconDefinition,
      farUsers as IconDefinition,
      faFolderOpen as IconDefinition,
      farUnlock,
      faBrowsers as IconDefinition,
      faPlusCircle,
      farCircleInfo as IconDefinition,
      faCircleInfo,
      faSpinner,
      faListCheck as IconDefinition,
      faCheck,
      faHelmetSafety,
      faTypewriter as IconDefinition,
      faFileArrowUp as IconDefinition,
      faCheck,
      fadUserPlus as IconDefinition,
      faPaperPlane as IconDefinition,
      faIndustryWindows as IconDefinition,
      faHandshake,
      faGears,
      faRectangleHistory as IconDefinition,
      faChair,
      fasUserGroup as IconDefinition,
      faBagShopping as IconDefinition,
      farCircleCheck as IconDefinition,
      faShuffle,
      faObjectUngroup,
      farCircleQuestion as IconDefinition,
      faUserSecret as IconDefinition,
      faOctagonExclamation as IconDefinition,
      faFileExport,
      fadUserSecret as IconDefinition,
      faCaretLeft
    );
    app.component("FontAwesomeIcon", FontAwesomeIcon);
  }
};
