const CartView = () => import("./CartView.vue");

const cartRoutes = [
  {
    path: "/cart",
    name: "Cart",
    component: CartView,
    meta: {
      requiresAuth: true
    }
  }
];

export default cartRoutes;
