import type { Ref, ComputedRef } from "vue";

export const csrfTokenKey = ["csrfToken"];

/******** Group *********/
export const groupKeys = {
  all: ["groups"],
  lists: () => [...groupKeys.all, "list"],
  list: (filters: { [key: string]: any }) => [...groupKeys.lists(), { ...filters }],
  details: () => [...groupKeys.all, "detail"],
  detail: (groupId: string) => [...groupKeys.details(), groupId]
};

export const groupTypeKeys = {
  all: (groupId: string) => ["groupTypes", groupId]
};

export const groupShippingAddressesKeys = {
  all: (groupId: string) => ["groupShippingAddresses", groupId]
};

export const groupEnrollmentsKeys = {
  all: ["groupEnrollmentsKeys"], // ["groupEnrollmentsKeys"]
  lists: () => [...groupEnrollmentsKeys.all, "list"], // ["groupEnrollmentsKeys", "list"]
  list: (groupId: string, filters: { [key: string]: any }) => [
    ...groupEnrollmentsKeys.lists(),
    groupId,
    { ...filters }
  ], // ["groupEnrollmentsKeys", "list", 1, {}]
  details: () => [...groupEnrollmentsKeys.all, "detail"], // ["groupEnrollmentsKeys", "detail"]
  detail: (groupId: string, enrollmentId: string) => [
    ...groupEnrollmentsKeys.details(),
    groupId,
    enrollmentId
  ] // ["groupEnrollmentsKeys", "detail", 1, 2]
};

export const groupProductsKeys = {
  all: ["groupProductsKeys"], // ["groupProductsKeys"]
  lists: () => [...groupProductsKeys.all, "list"], // ["groupProductsKeys", "list"]
  list: (groupId: string, filters: { [key: string]: any }) => [
    ...groupProductsKeys.lists(),
    groupId,
    { ...filters }
  ], // ["groupProductsKeys", "list", 1, {}]
  details: () => [...groupProductsKeys.all, "detail"], // ["groupProductsKeys", "detail"]
  detail: (groupId: string, productId: string) => [
    ...groupProductsKeys.details(),
    groupId,
    productId
  ] // ["groupProductsKeys", "detail", 1, 2]
};

export const groupProductSeatsKeys = {
  all: ["groupProductSeatsKeys"], // ["groupProductSeatsKeys"]
  lists: () => [...groupProductSeatsKeys.all, "list"], // ["groupProductSeatsKeys", "list"]
  list: (groupId: string, productId: string, filters: { [key: string]: any }) => [
    ...groupProductSeatsKeys.lists(),
    groupId,
    productId,
    { ...filters }
  ], // ["groupProductSeatsKeys", "list", 1, 2, {}]
  details: () => [...groupProductSeatsKeys.all, "detail"], // ["groupProductSeatsKeys", "detail"]
  detail: (groupId: string, productId: string, seatId: string) => [
    ...groupProductSeatsKeys.details(),
    groupId,
    productId,
    seatId
  ] // ["groupProductSeatsKeys", "detail", 1, 2, 3]
};

export const groupCourseResourcesKey = ["groupCourseResources"];

/******** Organization *********/
export const organizationBillingContactsKeys = {
  all: (organizationId: string) => ["organizationBillingContacts", organizationId]
};

export const organizationGroupsKeys = {
  all: ["organizationGroupsKeys"],
  lists: () => [...organizationGroupsKeys.all, "list"],
  list: (organizationId: string, groupId: string) => [
    ...organizationGroupsKeys.lists(),
    organizationId,
    groupId
  ]
};

export const organizationProductsKeys = {
  all: ["organizationProductsKeys"],
  lists: () => [...organizationProductsKeys.all, "list"],
  list: (organizationId: string) => [...organizationProductsKeys.lists(), organizationId]
};

export const organizationSeatExpirationKeys = {
  all: ["organizationSeatExpirationKeys"],
  lists: () => [...organizationSeatExpirationKeys.all, "list"],
  list: (organizationId: string, productId: ComputedRef<string>) => [
    ...organizationSeatExpirationKeys.lists(),
    organizationId,
    productId
  ]
};

export const organizationSeatsProductsDistributionKeys = {
  all: ["organizationSeatsProductsDistributionKeys"],
  lists: () => [...organizationSeatsProductsDistributionKeys.all, "list"],
  list: (organizationId: string, productId: ComputedRef<string>) => [
    ...organizationSeatsProductsDistributionKeys.lists(),
    organizationId,
    productId
  ]
};

export const organizationSeatsProductsDistributionGroupsKeys = {
  all: ["organizationSeatsProductsDistributionGroupsKeys"],
  lists: () => [...organizationSeatsProductsDistributionGroupsKeys.all, "list"],
  list: (organizationId: string, productId: ComputedRef<string>) => [
    ...organizationSeatsProductsDistributionGroupsKeys.lists(),
    organizationId,
    productId
  ]
};

export const orderPermissionOrgsKey = ["orderPermissionOrgs"];
export const licenseOrgsKey = ["licenseOrgs"];
export const ordersFilterOrgsKey = ["ordersFilterOrgs"];

/******** Member *********/
export const memberKeys = {
  all: (groupId: string) => ["members", groupId],
  lists: (groupId: string) => [...memberKeys.all(groupId), "list"],
  list: (groupId: string, filters: { [key: string]: any }) => [
    ...memberKeys.lists(groupId),
    { ...filters }
  ],
  details: (groupId: string) => [...memberKeys.all(groupId), "detail"],
  detail: (groupId: string, userId: string) => [...memberKeys.details(groupId), userId]
};

/******** SubGroup *********/
export const subgroupKeys = {
  all: (groupId: string) => ["subgroups", groupId],
  lists: (groupId: string) => [...subgroupKeys.all(groupId), "list"],
  list: (groupId: string, filters: { [key: string]: any }) => [
    ...subgroupKeys.lists(groupId),
    { ...filters }
  ]
};

/******** Catalog *********/
export const catalogKeys = {
  all: ["catalogs"],
  lists: () => [...catalogKeys.all, "list"],
  list: (filters: { [key: string]: any }) => [...catalogKeys.lists(), { ...filters }],
  details: () => [...catalogKeys.all, "detail"],
  detail: (catalogId: string) => [...catalogKeys.details(), catalogId]
};

/******** Ecommerce *********/
export const cartKey = ["cart"];
export const anonOrderKey = ["anonOrder"];
export const shippingOptionsKey = ["shippingOptions"];
export const licenseKeys = {
  all: (orgId: string) => ["licenses", orgId],
  lists: (orgId: string) => [...licenseKeys.all(orgId), "list"],
  list: (orgId: string, filters: { [key: string]: any }) => [
    ...licenseKeys.lists(orgId),
    { ...filters }
  ]
};

export const orderKeys = {
  all: ["orders"],
  lists: () => [...orderKeys.all, "list"],
  list: (filters: { [key: string]: any }) => [...orderKeys.lists(), { ...filters }],
  details: () => [...orderKeys.all, "detail"],
  detail: (orderId: string) => [...orderKeys.details(), orderId],
  statuses: () => [...orderKeys.all, "status"],
  status: (orderId: string) => [...orderKeys.statuses(), orderId]
};

/******** Enrollments *********/
export const enrollmentKeys = {
  all: ["enrollments"],
  details: () => [...enrollmentKeys.all, "detail"],
  detail: (enrollmentId: string) => [...enrollmentKeys.details(), enrollmentId]
};

export const enrollmentAssessmentKeys = {
  all: ["enrollmentAssessmentKeys"],
  lists: () => [...enrollmentAssessmentKeys.all, "list"],
  list: (groupId: string, enrollmentId: Ref<string>, assessmentId: Ref<string>) => [
    ...groupProductSeatsKeys.lists(),
    groupId,
    enrollmentId,
    assessmentId
  ]
};

/******** Gradebook *********/
export const gradebookKeys = {
  all: (organizationId: number) => ["gradebooks", organizationId],
  lists: (organizationId: number) => [...gradebookKeys.all(organizationId), "list"],
  list: (organizationId: number, filters: { [key: string]: any }) => [
    ...gradebookKeys.lists(organizationId),
    { ...filters }
  ]
};

/******** User Management *********/
export const userKeys = {
  all: ["users"],
  lists: () => [...userKeys.all, "list"],
  list: (filters: { [key: string]: any }) => [...userKeys.lists(), { ...filters }],
  details: () => [...userKeys.all, "detail"],
  detail: (userId: string) => [...userKeys.details(), userId]
};

export const usersFilterOrgsKeys = ["usersFilterOrgs"];

/******** Authentication *********/
export const passwordKey = ["password"];
export const userShippingAddressKey = ["userShippingAddress"];

/******** Utilities *********/
export const securityQuestionsKey = ["securityQuestions"];
export const instructorActionsKey = ["instructorActions"];
