import api from "../api";
import { useQuery } from "@tanstack/vue-query";
import { cartKey } from "../queryKeys";

const getCart = async () => {
  const response = await api.get("/carts");

  return response.data;
};

const useGetCartQuery = (isEnabled: boolean = true) => {
  return useQuery({
    queryKey: cartKey,
    queryFn: () => getCart(),
    enabled: isEnabled
  });
};

export default useGetCartQuery;
