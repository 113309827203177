export const getCourseStatusSelector = (courseStatusValue: string) => {
  switch (courseStatusValue) {
    case "NOT_STARTED":
      return "--not-started";
    case "IN_PROGRESS":
      return "--in-progress";
    case "LOCKED":
      return "--locked";
    case "LAPSED":
      return "--lapsed";
    case "IN_REMEDIATION":
      return "--in-remediation";
    case "FAILED":
      return "--failed";
    case "PASSED":
      return "--passed";
    case "COMPLETED":
      return "--completed";
    case "NOT_ENROLLED":
      return "--not-enrolled";
    default:
      return "";
  }
};
