const SignOutView = () => import("./SignOutView.vue");

const signOutRoutes = [
  {
    path: "/signout",
    name: "SignOut",
    component: SignOutView,
    meta: {
      requiresAuth: true
    }
  }
];

export default signOutRoutes;
