import { setLocale } from "yup";

export default {
  install: () => {
    // Documentation: https://github.com/jquense/yup?tab=readme-ov-file#error-message-customization
    setLocale({
      mixed: {
        required: ({ path }) => `${path} is required`
      }
    });
  }
};
