const OrganizationSeatsProductsView = () => import("./OrganizationSeatsProductsView.vue");

const organizationRoutes = [
  {
    path: "/organizations/:organizationId/seats-products/:productId?",
    name: "OrganizationSeatsProducts",
    component: OrganizationSeatsProductsView,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
];

export default organizationRoutes;
