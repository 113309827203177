import { defineStore } from "pinia";
interface State {
  order_id: string | null;
  zip_code: string | null;
}

export const useAnonymousOrderStore = defineStore("anonymousOrder", {
  state: (): State => {
    return {
      order_id: null,
      zip_code: null
    };
  },

  getters: {
    isAnonymous: (state: State) => state.order_id !== null && state.zip_code !== null
  },

  actions: {
    setAnonymousOrder({ order_id, zip_code }: State) {
      this.order_id = order_id;
      this.zip_code = zip_code;
    },

    removeAnonymousOrder() {
      // Remove the data from global state
      this.order_id = null;
      this.zip_code = null;
    }
  },

  persist: true
});
