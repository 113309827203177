const OrdersView = () => import("./OrdersView.vue");
const OrderDetailsView = () => import("./OrderDetailsView.vue");
const OrderConfirmationView = () => import("../orders/OrderConfirmationView.vue");
const AnonymousOrderConfirmationView = () => import("../orders/AnonymousOrderConfirmationView.vue");
const PayNowForm = () => import("@/components/checkout/PayNowForm.vue");
const AnonymousCheckout = () => import("./AnonymousCheckout.vue");

const orderRoutes = [
  {
    path: "/orders",
    name: "OrdersView",
    component: OrdersView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/orders/:id",
    name: "OrderDetails",
    component: OrderDetailsView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/orders/confirmation",
    name: "OrderConfirmation",
    component: OrderConfirmationView,
    meta: {
      requiresAuth: true
    }
  },
  // Anonymous Order Routes
  {
    path: "/pay-now/confirmation",
    name: "AnonymousOrderConfirmation",
    component: AnonymousOrderConfirmationView,
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/pay-now",
    name: "PayNow",
    component: PayNowForm,
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/pay-now/checkout",
    name: "AnonymousCheckout",
    component: AnonymousCheckout,
    props: true,
    meta: {
      requiresAuth: false
    }
  }
];

export default orderRoutes;
