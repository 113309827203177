import type { App } from "vue";
import VueGtag from "vue-gtag";
import router from "../router";

export default {
  install: (app: App) => {
    app.use(
      VueGtag,
      {
        config: { id: "G-TZF1JS5NQ5" }
      },
      router
    );
  }
};
