import PrimeVue from "primevue/config";
import Skeleton from "primevue/skeleton";
import type { App } from "vue";
import type { PanelMenuContext } from "primevue/panelmenu";

export default {
  install: (app: App) => {
    app.use(PrimeVue, {
      unstyled: true,
      pt: {
        // Skeleton
        skeleton: {
          root: "p-skeleton",
          hooks: "p-skeleton__hooks"
        },

        // PanelMenu
        panelMenu: {
          menu: {
            class: "p-panelmenu__menu"
          },
          header: {
            class: "p-panelmenu__header"
          },
          headerContent: ({ context }: { context: PanelMenuContext }) => ({
            class: [
              "p-panelmenu__header-content",
              {
                "p-panelmenu__header-content--active": context.active
              }
            ]
          }),
          panel: {
            class: "p-panelmenu__panel"
          },
          menuContent: {
            class: "p-panelmenu__menu-content"
          }
        },
        inputSwitch: {
          root: "p-inputswitch",
          input: "p-inputswitch-input",
          slider: "p-inputswitch-slider"
        },

        // Toast
        toast: {
          root: "p-toast",
          message: "p-toast__message",
          container: "p-toast__container",
          content: "p-toast__content",
          icon: "p-toast__icon",
          text: "p-toast__text",
          summary: "p-toast__summary",
          detail: "p-toast__detail",
          buttonContainer: "p-toast__button-container",
          button: "p-toast__button",
          closeButton: "p-toast__close-button",
          buttonIcon: "p-toast__button-icon",
          closeIcon: "p-toast__close-icon",
          hooks: "p-toast__hooks",
          transition: {
            enterFromClass: "p-toast-enter-from",
            enterActiveClass: "p-toast-enter-active",
            leaveFromClass: "p-toast-leave-from",
            leaveActiveClass: "p-toast-leave-active",
            leaveToClass: "p-toast-leave-to"
          }
        },

        // Listbox
        listbox: {
          root: "p-listbox",
          header: "p-listbox__header",
          filterContainer: "p-listbox__filter-container",
          filterInput: "p-listbox__filter-input",
          filterIcon: "p-listbox__filter-icon",
          wrapper: "p-listbox__wrapper",
          virtualScroller: "p-listbox__virtual-scroller",
          list: "p-listbox__list",
          itemGroup: "p-listbox__item-group",
          item: "p-listbox__item",
          emptyMessage: "p-listbox__empty-message",
          hiddenFirstFocusableEl: "p-listbox__hidden-first-focusable-el",
          hiddenFilterResult: "p-listbox__hidden-filter-result",
          hiddenLastFocusableEl: "p-listbox__hidden-last-focusable-el"
        }
      }
    });

    app.component("PSkeleton", Skeleton);
  }
};
