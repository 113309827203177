const CoursesView = () => import("./CoursesView.vue");

const coursesRoutes = [
  {
    path: "/courses",
    name: "CoursesView",
    component: CoursesView,
    meta: {
      requiresAuth: true
    }
  }
];

export default coursesRoutes;
