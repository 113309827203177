const GradebookView = () => import("./GradebookView.vue");
const GradebookSelectionView = () => import("./GradebookSelectionView.vue");

const gradebookRoutes = [
  {
    path: "/gradebook",
    name: "GradebookSelection",
    component: GradebookSelectionView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/gradebook/:id",
    name: "Gradebook",
    component: GradebookView,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
];

export default gradebookRoutes;
