const InternalView = () => import("./InternalView.vue");
const InternalCreateOrganizationView = () => import("./InternalCreateOrganizationView.vue");

const internalRoutes = [
  {
    path: "/internal",
    name: "Internal",
    component: InternalView,
    meta: {
      requiresAuth: true
    },
    redirect: () => {
      return { name: "InternalCreateOrganization" };
    },
    children: [
      {
        path: "create-organization",
        name: "InternalCreateOrganization",
        component: InternalCreateOrganizationView
      }
    ]
  }
];

export default internalRoutes;
