import axios from "axios";
import { useUserStore } from "@/stores/user";
import type { AxiosInstance, CreateAxiosDefaults, AxiosRequestConfig } from "axios";
import router from "@/router";

// Documentation: https://axios-http.com/docs/req_config
const config: CreateAxiosDefaults = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
  withXSRFToken: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-Csrftoken"
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const store = useUserStore();

    // 401 - Unauthorized
    if (error.response.status === 401) {
      store.handleAuthentication();
      // 403 - Not authenticated
    } else if (error.response.status === 403 && error.response?.data.errors[0].code === 4003) {
      store.handleAuthentication();
      // 403 - Forbidden
    } else if (error.config.method === "get" && error.response.status === 403) {
      router.push({ name: "PageRestricted" });
      // 404 - Not Found
    } else if (error.config.method === "get" && error.response.status === 404) {
      // Skip GET request for Salesforce account
      if (!error.config.url.includes("/internal/salesforce_accounts/")) {
        router.push({ name: "NotFound" });
      }
      // 500 - Server Error
    } else if (error.config.method === "get" && error.response.status === 500) {
      router.push({ name: "ServerError" });
    }

    return Promise.reject(error);
  }
);

const api = (axios: AxiosInstance) => {
  return {
    get: (url: string, config?: AxiosRequestConfig) => axios.get(url, config),

    post: (url: string, data?: any, config?: AxiosRequestConfig) => axios.post(url, data, config),

    patch: (url: string, data?: any, config?: AxiosRequestConfig) => axios.patch(url, data, config),

    put: (url: string, data?: any, config?: AxiosRequestConfig) => axios.put(url, data, config),

    delete: (url: string, config?: AxiosRequestConfig) => axios.delete(url, config)
  };
};

export default api(axiosInstance);
