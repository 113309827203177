const UsersView = () => import("./UsersView.vue");
const UserDetailsView = () => import("./UserDetailsView.vue");

const userRoutes = [
  {
    path: "/users",
    name: "UsersView",
    component: UsersView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/:id",
    name: "UserDetails",
    component: UserDetailsView,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
];

export default userRoutes;
