<template>
  <UserImpersonationBanner />
  <header>
    <!-- This is for the maintenance banner teleport -->
    <div id="nav-bar-header-maintenance-banner" />
    <nav class="header">
      <div v-if="isMobile" class="header__mobile-content">
        <button v-if="showMobileMenu" @click="showNavDrawer" aria-label="Navigation">
          <KIcon
            v-if="store.isAuthenticated"
            icon="fa-solid fa-bars"
            class="header__mobile-icon"
            size="xl"
          />
        </button>
        <button aria-label="Home" @click="onHome">
          <img
            src="@/assets/images/careersafe-logo-horizontal-1c-white.svg"
            height="33rem"
            alt="CareerSafe Logo"
          />
        </button>
      </div>
      <div v-if="showMobileProfile">
        <RouterLink
          v-if="cartData !== null"
          id="cartLink"
          :to="{ name: 'Cart' }"
          class="header__link header-profile__cart"
        >
          <KIcon icon="fa-solid fa-cart-shopping" size="lg" />
          <span class="sr-only">Cart Navigation Link</span>
        </RouterLink>
        <button @click="showProfileNavDrawer" aria-label="Profile Navigation">
          <KIcon icon="fa-solid fa-user-circle" class="header__mobile-user-icon" size="lg" />
          <KIcon icon="fa-solid fa-caret-down" class="header__mobile-user-icon" size="sm" />
        </button>
      </div>
      <div v-if="!isMobile" class="header__content">
        <button id="header-logo" class="header-logo" aria-label="Home" @click="onHome">
          <img
            src="@/assets/images/careersafe-logo-horizontal-1c-white.svg"
            height="40rem"
            alt="CareerSafe Logo"
          />
        </button>
        <div v-for="navItem in userNavInfo" :key="navItem.id">
          <RouterLink
            v-if="showNavItem(navItem)"
            :id="navItem.id"
            :to="navItem.route"
            class="header__link"
            :class="
              navItem.id == 'gradebook-nav-link' && router.currentRoute.value.name == 'Gradebook'
                ? 'router-link-active'
                : ''
            "
          >
            {{ navItem.title }}
          </RouterLink>

          <KDropdown
            v-else-if="showNavItemDropdown(navItem)"
            :id="navItem.id"
            :model="navItem.children"
            :aria-label="navItem.ariaLabel"
            class="header__dropdown"
            text
          >
            <template #icon>
              <div class="header__dropdown-label">
                {{ navItem.title }}
              </div>
              <KIcon icon="fa-solid fa-caret-down" class="header__dropdown-icon" size="xs" />
            </template>
          </KDropdown>
        </div>
      </div>
      <div v-if="showProfile" class="header-profile">
        <RouterLink
          v-if="cartData !== null"
          id="cartLink"
          :to="{ name: 'Cart' }"
          class="header__link header-profile__cart"
        >
          <KIcon icon="fa-solid fa-cart-shopping" size="lg" />
          <span class="sr-only">Cart Navigation Link</span>
        </RouterLink>
        <KDropdown
          v-if="showProfile"
          id="manage-profile-options"
          :model="profileNavItem.children"
          :aria-label="profileNavItem.ariaLabel"
          class="header__dropdown"
          text
        >
          <template #icon>
            <div class="header__dropdown-label">
              {{ profileNavItem.title }}
            </div>
            <KIcon icon="fa-solid fa-caret-down" class="header__dropdown-icon" size="xs" />
          </template>
        </KDropdown>
      </div>
    </nav>
  </header>

  <!-- Nav Drawer -->
  <LayoutHeaderNavigationDrawer ref="layoutHeaderNavigationDrawer" />

  <!-- Profile Drawer -->
  <LayoutHeaderProfileDrawer
    ref="layoutHeaderProfileDrawer"
    @start-switch-organization="onOpenSwitchOrganizationModal"
  />

  <!-- Modals -->
  <OrganizationSwitchDashboardModal ref="switchOrganizationModal" />
</template>

<script setup lang="ts">
import { KDropdown, KIcon } from "@k2united/frontend_components";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { computed, ref, defineAsyncComponent, watch } from "vue";
import { useResponsive } from "@/composables/useResponsive";
import type { UserNavObject } from "@/types";
import useGetCartQuery from "@/api/queries/useGetCartQuery";

const LayoutHeaderNavigationDrawer = defineAsyncComponent(
  () => import("../../components/layout/LayoutHeaderNavigationDrawer.vue")
);
const LayoutHeaderProfileDrawer = defineAsyncComponent(
  () => import("../../components/layout/LayoutHeaderProfileDrawer.vue")
);
const OrganizationSwitchDashboardModal = defineAsyncComponent(
  () => import("../../components/organization/OrganizationSwitchDashboardModal.vue")
);
const UserImpersonationBanner = defineAsyncComponent(
  () => import("../../components/users/UserImpersonationBanner.vue")
);

const { isMobile } = useResponsive();
const store = useUserStore();
const router = useRouter();

const onHome = () => {
  if (store.isPasswordResetRequired) return;

  router.push({ name: "Home" });
};

// Cart Data
const { data: rawCartData, refetch } = useGetCartQuery(store.isAuthenticated);
const cartData = computed(() => {
  if (rawCartData.value && Object.keys(rawCartData.value.data).length > 0) {
    return rawCartData.value.data;
  } else {
    return null;
  }
});

watch(
  () => store.isAuthenticated,
  (isAuthenticated) => {
    if (isAuthenticated) {
      refetch();
    }
  }
);

// Modals
const onOpenSwitchOrganizationModal = () => {
  switchOrganizationModal.value.showModal();
};

// Nav Buttons
const userNavInfo = computed(() => {
  return store.getNavInfo;
});

const profileNavItem = computed(() => {
  const profileNavInfo = store.getProfileNavInfo;

  for (let i = 0; i < profileNavInfo.children.length; i++) {
    if (profileNavInfo.children[i].class === "org-switch-sub-nav-link") {
      profileNavInfo.children[i].command = () => {
        onOpenSwitchOrganizationModal();
      };
    }
  }

  return profileNavInfo;
});

const showMobileMenu = computed(
  () => isMobile.value && store.isAuthenticated && !store.isPasswordResetRequired
);

const showMobileProfile = computed(
  () => isMobile.value && store.isAuthenticated && !store.isPasswordResetRequired
);

const showNavItem = (navItem: UserNavObject) =>
  navItem.children.length === 0 && store.isAuthenticated && !store.isPasswordResetRequired;

const showNavItemDropdown = (navItem: UserNavObject) =>
  navItem.children.length !== 0 && store.isAuthenticated && !store.isPasswordResetRequired;

const showProfile = computed(
  () => store.isAuthenticated && !store.isPasswordResetRequired && !isMobile.value
);

//Mobile Drawer
const layoutHeaderNavigationDrawer = ref();
const layoutHeaderProfileDrawer = ref();
const switchOrganizationModal = ref();

const showNavDrawer = () => {
  layoutHeaderNavigationDrawer.value.showDrawer(true);
};

const showProfileNavDrawer = () => {
  layoutHeaderProfileDrawer.value.showDrawer(true);
};
</script>

<style scoped lang="scss">
button {
  font-family: $font-family-button;
  font-weight: 600;
  &:focus-visible {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px $color-shadow-button-focused;
  }
}

.header-logo {
  padding-right: 40px;
  &:focus-visible {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px $color-shadow-button-focused;
  }
}

.header {
  align-items: center;
  background-color: $color-industry-blue-default;
  display: flex;
  flex-direction: row;
  height: 53px;
  justify-content: space-between;
  padding: 0 0.5rem 0 0.5rem;
  width: 100%;
}
.header__content {
  align-items: center;
  column-gap: 1.25rem;
  display: flex;
  flex-direction: row;
}

.header__mobile-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header__link {
  color: $color-standard-neutral-neutral0;
  font-family: $font-family-button;
  font-weight: 600;
  padding: 12px;
  text-decoration: none;
  &:focus-visible {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px $color-shadow-button-focused;
  }
}

.header__dropdown-label {
  color: $color-standard-neutral-neutral0;
  font-weight: 600;
  padding-right: 10px;
  text-decoration: none;
}

.header__dropdown-icon {
  color: $color-standard-neutral-neutral0;
  padding-bottom: 4px;
}

.router-link-active {
  border-bottom: solid $color-standard-neutral-neutral0 4px;
}

.header__dropdown {
  padding: 13px;
  &:focus-visible {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px $color-shadow-button-focused;
  }
}

:deep(.header__dropdown:focus) {
  box-shadow: none;
}

.header__mobile-icon {
  color: $color-standard-neutral-neutral0;
  padding: 0.7rem;
}
.header__mobile-user-icon {
  color: $color-standard-neutral-neutral0;
  padding-right: 0.2rem;
}
.header-profile {
  display: flex;
}
.header-profile__cart {
  padding: 0.875rem;
}
</style>

<style lang="scss">
.sub-menu__border {
  border-top: solid 1px $color-border-subtle;
}

.sub-menu__text {
  .k-menu__label {
    color: $color-text-brand;
    font-weight: 600;
  }
}

.k-dropdown {
  .header__dropdown {
    padding: 13px;
    &:focus-visible {
      box-shadow:
        0 0 0 2px #ffffff,
        0 0 0 4px $color-shadow-button-focused;
    }
  }
}
</style>
