const NotFoundView = () => import("./NotFoundView.vue");

const notFoundRoutes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
    meta: {
      requiresAuth: false
    }
  }
];

export default notFoundRoutes;
