import ToastEventBus from "primevue/toasteventbus";
import { PrimeVueToastSymbol } from "primevue/usetoast";
import type { ToastMessageOptions } from "primevue/toast";
import type { App } from "vue";

export default {
  // Documentation: https://primevue.org/toast/
  install: (app: App) => {
    const ToastService = {
      add: (message: ToastMessageOptions) => {
        // Toast disappears after 15 sec by default
        ToastEventBus.emit("add", {
          life: 15000,
          ...message
        });
      },

      remove: (message: ToastMessageOptions) => {
        ToastEventBus.emit("remove", message);
      },

      removeGroup: (group: string) => {
        ToastEventBus.emit("remove-group", group);
      },

      removeAllGroups: () => {
        ToastEventBus.emit("remove-all-groups");
      }
    };

    app.config.globalProperties.$toast = ToastService;
    app.provide(PrimeVueToastSymbol, ToastService);
  }
};
