export function formatElapsedTimeDisplay(minutes: number) {
  const numHours = minutes / 60 >= 1 ? Math.floor(minutes / 60) : 0;

  const hours = Intl.NumberFormat("en-US", {
    minimumIntegerDigits: 1,
    maximumFractionDigits: 0
  }).format(numHours);
  const remainingMinutes = Intl.NumberFormat("en-US", {
    minimumIntegerDigits: 2
  }).format(minutes % 60);
  return `${hours}h ${remainingMinutes}m`;
}
