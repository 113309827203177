const ErrorView = () => import("./ErrorView.vue");
const ServerErrorView = () => import("./ServerErrorView.vue");
const PageRestrictedView = () => import("./PageRestrictedView.vue");
const EcommerceDisabledView = () => import("./EcommerceDisabledView.vue");

const errorRoutes = [
  {
    path: "/error",
    name: "Error",
    component: ErrorView,
    props: false,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/server-error",
    name: "ServerError",
    component: ServerErrorView,
    props: false,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/page-restricted",
    name: "PageRestricted",
    component: PageRestrictedView,
    props: false,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/ecommerce-disabled",
    name: "EcommerceDisabled",
    component: EcommerceDisabledView,
    props: false,
    meta: {
      requiresAuth: false
    }
  }
];

export default errorRoutes;
