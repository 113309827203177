import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";

export function useResponsive() {
  const { width } = useWindowSize();

  const isMobile = computed(() => width.value < 769);

  const isTablet = computed(() => width.value >= 769 && width.value < 1024);

  const isDesktop = computed(() => width.value >= 1024);

  const isWidescreen = computed(() => width.value >= 1216);

  return { isMobile, isTablet, isDesktop, isWidescreen };
}
