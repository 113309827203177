const CatalogView = () => import("./CatalogView.vue");
const CatalogsView = () => import("./CatalogsView.vue");

const catalogRoutes = [
  {
    path: "/catalogs",
    name: "Catalogs",
    component: CatalogsView
  },
  {
    path: "/catalogs/:catalogId",
    name: "Catalog",
    component: CatalogView,
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/catalogs/retail",
    name: "CatalogRetail",
    component: CatalogView,
    props: { catalogId: import.meta.env.VITE_CATALOG_RETAIL_ID },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/catalogs/education",
    name: "CatalogEducation",
    component: CatalogView,
    props: { catalogId: import.meta.env.VITE_CATALOG_EDUCATION_ID },
    meta: {
      requiresAuth: false
    }
  }
];

export default catalogRoutes;
