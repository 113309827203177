<template>
  <DefaultLayout>
    <RouterView />
    <BaseToast />
  </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BaseToast from "@/components/base/BaseToast.vue";
import { useHead } from "@unhead/vue";

useHead({
  titleTemplate: (title?: string) => (!title ? "CareerSafe" : `${title} - CareerSafe`)
});
</script>

<style lang="scss">
@use "./src/assets/scss/main.scss";
</style>
