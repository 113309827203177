export function formatDateDisplay(dateString: string) {
  /* 
  We do not save timezone information for date fields in the database.
  This means that every time we need to display a date field, we should not convert it to 
  a specific timezone.

  For datetime fields, we do want to convert to ET.
  */
  if (!dateString) {
    return "—";
  }

  const date = Date.parse(dateString);
  if (dateString.includes(":")) {
    // format in ET
    return Intl.DateTimeFormat("en-US", {
      timeZone: "America/New_York",
      year: "numeric",
      day: "2-digit",
      month: "2-digit"
    }).format(date);
  }

  // Otherwise, use UTC
  return Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    year: "numeric",
    day: "2-digit",
    month: "2-digit"
  }).format(date);
}
