const AcceptInviteView = () => import("./AcceptInviteView.vue");

const inviteRoutes = [
  {
    path: "/accept_group_invite/:token",
    name: "Accept Invite",
    component: AcceptInviteView,
    props: true,
    meta: {
      requiresAuth: false
    }
  }
];

export default inviteRoutes;
