const HelpView = () => import("./HelpView.vue");

const helpRoutes = [
  {
    path: "/help",
    name: "Help",
    component: HelpView,
    meta: {
      requiresAuth: true
    }
  }
];

export default helpRoutes;
