const SignInView = () => import("./SignInView.vue");

const signInRoutes = [
  {
    path: "/signin",
    name: "SignIn",
    component: SignInView,
    meta: {
      requiresAuth: false
    }
  }
];

export default signInRoutes;
