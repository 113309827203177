const EnrollmentView = () => import("./EnrollmentView.vue");
const enrollmentRoutes = [
  {
    path: "/enrollment/:enrollmentId",
    name: "Enrollment",
    component: EnrollmentView,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
];

export default enrollmentRoutes;
